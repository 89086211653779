
export default function() {
  return [
    {
      component: 'Section',
      props: {
        class: 'contact-page',
        width: 'medium',
      },
      blocks: {
        default: [
          {
            component: 'ContactList',
            data: {
              contacts: this.$path('item.roles.salesman'),
            },
            props: {
              class: 'contact-page__salesman',
              showLogo: false,
              contactDetailStyle: 'icon',
            },
          },
          {
            component: 'Lead',
            props: {
              class: 'contact-page__lead',
            },
          },
        ],
      },
    },
  ]
}
