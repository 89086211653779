<template>
  <Loader style="flex-grow: 1">
    <Blocks :blocks="config" :customComponents="customComponents"></Blocks>
  </Loader>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Lead from '@/components/Project/Lead'

import { Blocks } from '@kvass/pagebuilder'
import config from '@/config/contact.js'

export default {
  computed: {
    ...mapState('Project', ['item']),
    config() {
      return config.call(this)
    },
    customComponents() {
      return { Lead }
    },
  },
  methods: {
    ...mapActions('Project', ['fetch']),
  },
  created() {
    this.fetch()
  },
  metaInfo() {
    return {
      title: this.$t('leadFormTitle'),
    }
  },
  components: {
    Lead,

    Blocks,
  },
}
</script>

<style lang="scss">
.contact-page {
  padding-bottom: 10%;
  padding-top: 15%;

  @keyframes PageTransition {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  &__salesman {
    text-align: center;
    display: flex;
    flex-direction: column;
    color: black;
    justify-content: center;
    align-items: center;

    margin-bottom: 10vh;

    @include respond-above('phone') {
      flex-direction: row;
      flex-wrap: wrap;

      gap: 0rem !important;
      margin-bottom: 20vh;
    }

    .kpb-contact-list {
      &__avatar {
        border-radius: 50%;
        opacity: 0;
        animation: PageTransition 0.4s 0.3s forwards;

        object-fit: cover;

        background: no-repeat 50% 50%;

        width: 255px;
        height: 255px;
      }

      &__item {
        gap: 0;
        margin-top: 3vh;

        align-items: center;

        @include respond-above('phone') {
          width: 50%;
        }
        @include respond-above('tablet') {
          width: 33.33333%;
        }

        // max-width: 30rem;
        // margin: 0 auto;
      }

      &__content {
        padding: 1.5rem;
      }

      &__name {
        margin-bottom: 7px !important;
        font-size: 24px !important;

        @include respond-above('phone') {
          font-size: 18px !important;
        }

        @include respond-above('desktop') {
          font-size: 24px !important;
        }
      }

      &__user-title {
        font-size: 14px;
      }

      &__phone,
      &__email {
        justify-content: center;

        font-size: 1rem;
        padding-top: 5px;
        padding-bottom: 5px;
      }

      &__contact-info {
        margin-top: 0rem !important;
        &-icon {
          opacity: 1;
        }
      }

      &__phone {
      }

      &__email {
      }
    }
  }

  &__lead {
    //'@/components/Project/Lead'
  }
}
</style>
